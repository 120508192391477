import { render, staticRenderFns } from "./cutting-block.vue?vue&type=template&id=6d3f860e&"
import script from "./cutting-block.vue?vue&type=script&lang=js&"
export * from "./cutting-block.vue?vue&type=script&lang=js&"
import style0 from "./cutting-block.vue?vue&type=style&index=0&id=6d3f860e&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports